.box {
  padding: 60px 0px;
}

.box-part {
  background: #fff;
  border-radius: 0;
  padding: 35px 5px;
  margin: 30px 0px;
}
.text {
  margin: 20px 0px;
}
